import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Cluster
} from '../EveryLayout';
import { ButtonBlue } from './';
import {
  CoverImageDrama,
  CoverImageEconLaw,
  CoverImageFineArts,
  CoverImageHome,
  CoverImageIB,
  CoverImageNatSci,
  CoverImageSocSci
} from './CoverImages';

import { useNodeProgrammeListData } from '../../hooks';

const Cover = ({ location }) => {

  const { edges } = useNodeProgrammeListData();

  const programmes = edges?.map((a) => {
    return a.node;
  });

  if (location.pathname === '/') {
    return <CoverHome />;
  }

  const programme = programmes.filter(item => item.path?.alias + '/' === location.pathname);

  if (programme.length) {
    return <CoverProgramme {...{ programme }} {...{ location }} />;
  }

  return null;
};

const CoverHomeOverlay = () => {
  return (
    <section className="_stack anim-cover in-view bg-light-blue-500 lg:shadow-md p-8 justify-center 2xl:px-16 md:ml-6 md:mb-6 lg:ml-12 lg:mb-12">
      <div className="prose">
        <h3 className="font-semibold sm:font-bold mb-0 text-2xl 2xl:text-3xl">
          Study English in the heart of Södermalm.
        </h3>
        <p className="mt-4 sm:hidden md:block sm:text-lg">
          Join thousands of students who have found their gateway to the world by studying in English at IEGS!
        </p>
      </div>
      {/* 'Browse Programmes' button */}
      <Box className="text-center sm:text-left">
        <ButtonBlue
          className="shadow-md mx-auto sm:-ml-4 2xl:shadow-lg"
          to="/academics/programmes/"
        >
          Browse Programmes
        </ButtonBlue>
      </Box>
    </section>
  );
};

const CoverHome = () => {
  return (
    <>
      <div className="relative lg:overflow-hidden">
        <div className="w-full h-full">
          <div className="!aspect-w-4 !aspect-h-3 lg:!aspect-w-16 lg:!aspect-h-9 xl:!aspect-h-8 2xl:!aspect-h-7">
            <CoverImageHome />
          </div>
          <Cluster className="hidden md:flex items-stretch justify-start absolute top-0 w-full h-full">
            <div className="box-content sm:relative sm:mx-0 md:w-1/5 2xl:w-1/6" />
            <div className="flex items-center justify-center sm:justify-start w-full sm:w-auto sm:mr-10" />
          </Cluster>
          <Cluster className="gap-0 w-full h-full items-stretch justify-start md:flex sm:absolute sm:top-0 sm:flex-nowrap">
            <div className="flex items-end justify-center w-full sm:w-auto">
              <div className="w-full">
                <CoverHomeOverlay />
              </div>
            </div>
            <div className="hidden box-content sm:block sm:w-1/5 md:w-1/2" />
          </Cluster>
        </div>
      </div>
    </>
  )
};

const CoverProgramme = ({ programme }) => {
  switch (programme[0].drupal_internal__nid) {
    case 859: // Aesthetics with Specialisation Fine Art
      return <CoverImageFineArts />
    case 860: // Drama
      return <CoverImageDrama />
    case 863: // Econ Law
      return <CoverImageEconLaw />
    case 864: // IB
      return <CoverImageIB />
    case 865: // Natural Science with Specialisation Natural Science
      return <CoverImageNatSci />
    case 866: // Social Science
      return <CoverImageSocSci />
    default:
      return <CoverImageIB />
  }
};

Cover.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

CoverProgramme.propTypes = {
  programme: PropTypes.arrayOf(PropTypes.shape({
    'drupal_internal__nid': PropTypes.number.isRequired,
  })).isRequired,
};

export default Cover;

